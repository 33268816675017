import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectionTable from '../SelectionTable';
import { Avatar, Button, ButtonGroup, CircularProgress, LinearProgress, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { ArrowDropDown, GetApp as Download, SettingsRemote } from '@material-ui/icons';
import * as colors from '../../colors';
import WizardAlert from './WizardAlert';
import { REPORT_INTERVALS } from '../../constants';
import axios from 'axios';
import { addInterval } from '../../utility-functions/intervalToDate';
import { addHours } from 'date-fns';
import { QUERY_SENSORDATA_VIEW_SHORT } from '../../utility-functions/gqlQueries';
import { useQuery } from '@apollo/client';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const REPORT_PROVIDER_URL = process.env.REACT_APP_REPORT_PROVIDER_URL;

const PDF_COLOR = '#eb0112';
const PDF_COLOR_DARKER = '#b2000e';
const CSV_COLOR = '#1e6d41';

const useStyles = makeStyles(theme => ({
	generateButton: {
		color: colors.primary,
		// borderColor: PDF_COLOR,
		// backgroundColor: 'darkorange',
		//fontWeight: 'bold',
		padding: '0.5rem 1rem',
		'&:hover': {
			color: 'white',
			backgroundColor: colors.primary,
		},
	},
	cancelButton: {
		color: PDF_COLOR,
		borderColor: PDF_COLOR,
		padding: '0.3rem 2rem',
		'&:hover': {
			color: 'white',
			backgroundColor: PDF_COLOR_DARKER,
		},
	},
}));

export default function CreateCsvReport({setShouldSave, handleClose, handleSave, loadReport, selectedData, ...props }) {
	const { t } = useTranslation();
	const [sensors, setSensors] = useState(loadReport?.sensors.map(sen => `${sen}`) || []);
	const classes = useStyles();
	const [selectedInterval, setSelectedInterval] = useState(REPORT_INTERVALS.week);
	const [intervalAnchorEl, setIntervalAnchorEl] = useState(null);
	const intervalOpen = Boolean(intervalAnchorEl);
	const [openDialog, setOpenDialog] = useState(false);
	const [csvStates, setCsvStates] = useState({
		loading: false,
		downloaded: false,
	});
	const repoSensorsLength = loadReport?.sensors.length || 0;
	const [alert, setAlert] = useState({
		open: false,
		message: '',
		severity: 'error',
	});

	const { data: sensorData, loading: sensorLoading } = useQuery(QUERY_SENSORDATA_VIEW_SHORT, {
		variables: {
			locationids: props.userInfo?.locationids,
		},
	});

	const [cancelCsvDownload, setCancelCsvDownload] = useState(() => () => null);

	const handleSaveInterval = interval => {
		setIntervalAnchorEl(null);
		setSelectedInterval(interval);
	};

	const setError = (message, severity = 'error') => {
		setAlert({ message, severity, open: true });
	};

	const resetError = () => {
		setAlert({ ...alert, open: false });
	};

	const selectionDone = () => {
				setShouldSave(false);
		if (sensors.length === 0) {
			return setError(t('reportPopups.noSensorsSelectedError'));
		}
		handleSave(sensors.map(sen => parseInt(sen)));
	};

	const handleButtonClick = () => {
		setShouldSave(true);

		if (sensors.length === 0) {
			return setError(t('reportPopups.noSensorsCsvError'), 'warning');
		}
		setOpenDialog(true);
	};
	const handleCloseDialog = () => {
		setShouldSave(false);
		setOpenDialog(false);
	};

	const handleConfirmSave = () => {
		handleSave(sensors.map(sen => parseInt(sen)));		setOpenDialog(false);
	};

	const downloadCsvToFile = csvFile => {
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([csvFile], { type: 'text/csv' }));
		link.download = 'report.csv';
		link.click();
	};

	const startCsvDownload = () => {
		if (sensors.length === 0) {
			return setError(t('reportPopups.noSensorsCsvError'), 'warning');
		}
		setCsvStates({
			...csvStates,
			loading: true,
		});
		cancelCsvDownload();
		const startOfToday = addInterval(new Date(), 'day', true, -1);
		const startOfPeriod = addInterval(startOfToday, selectedInterval.id, false, -1);
		axios
			.post(
				REPORT_PROVIDER_URL + 'reports/csv',
				{
					start: addHours(startOfPeriod, 2).toISOString().slice(0, 10),
					end: addHours(startOfToday, 2).toISOString().slice(0, 10),
					sensors: sensors.map(sen => parseInt(sen)),
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: props.userInfo?.accessToken,
					},
					responseType: 'text',
					cancelToken: new axios.CancelToken(cancel => setCancelCsvDownload(() => cancel)),
				}
			)
			.then(res => {
				downloadCsvToFile(res.data);
				setCsvStates({
					downloaded: true,
					loading: false,
				});
			})
			.catch(err => {
				if (!axios.isCancel(err)) {
					setError(t('reportPopups.csvDownloadError'), 'error');
					setCsvStates({
						...csvStates,
						loading: false,
					});
				}
			});
	};

	return (
		<>
			<WizardAlert
				open={alert.open}
				severity={alert.severity}
				onClose={resetError}
				style={{
					margin: '1rem 1rem 0 1rem',
					width: 'calc(100% - 2rem)',
				}}
				duration={8000}
			>
				{alert.message}
			</WizardAlert>
			<div
				style={{
					margin: '0 1rem 1rem 1rem',
				}}
			>
				<div style={{ display: 'flex', position: 'absolute', zIndex: 1, top: '5.4rem', right: '20rem', alignItems: 'center' }}>
					{csvStates.loading ? <CircularProgress size={'2rem'} style={{ margin: '0 1rem 0 0' }} /> : undefined}
					<ButtonGroup>
						<Button startIcon={<Download />} variant='outlined' className={classes.generateButton} onClick={startCsvDownload}>
							{t('reportPopups.csvDownloadButton')}
						</Button>
						<Button
							id='basic-button'
							aria-controls={intervalOpen ? 'basic-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={intervalOpen ? 'true' : undefined}
							endIcon={<ArrowDropDown />}
							variant='outlined'
							className={classes.generateButton}
							style={{ padding: '0 0.5rem 0 0.5rem' }}
							onClick={event => setIntervalAnchorEl(event.currentTarget)}
						>
							{selectedInterval.label}
						</Button>
					</ButtonGroup>
					<Menu
						id='basic-menu'
						anchorEl={intervalAnchorEl}
						open={intervalOpen}
						onClose={() => setIntervalAnchorEl(null)}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						{Object.values(REPORT_INTERVALS).map(interval => (
							<MenuItem key={interval.label} onClick={() => handleSaveInterval(interval)}>
								{interval.label}
							</MenuItem>
						))}
					</Menu>
				</div>
				<SelectionTable
					localization={{
						title: (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Avatar style={{ backgroundColor: colors.primary, color: 'white', width: '2.2rem', height: '2.2rem' }}>
									{<SettingsRemote />}
								</Avatar>
								<p style={{ marginLeft: '0.4rem' }}>{t('reports.selectSensorsReport') + ': ' + sensors.length}</p>
							</div>
						),
					}}
					data={sensorData?.getSensorView || []}
					dataId='sensorid'
					preselectedIds={loadReport?.sensors.map(sen => `${sen}`) || []}
					columns={[
						{ title: t('generic.name'), field: 'name' },
						{ title: t('reports.group'), field: 'groupname' },
						{ title: t('generic.address'), field: 'street' },
						{ title: t('generic.city'), field: 'city' },
					]}
					pageSizeOptions={[10, 15, 20]}
					onSelectionChange={rows => setSensors(rows.map(row => row.sensorid))}
					toolbarHeight={'5rem'}
					tableProps={{
						maxColumnLength: 32,
						options: {
							showTextRowsSelected: false,
							maxBodyHeight: 'calc(100vh - 22rem)',
						},
					}}
				/>
				{sensorLoading ? <LinearProgress /> : undefined}
				<div style={{ display: 'flex', float: 'right', margin: '1rem 0 0 0' }}>
					<Button variant='outlined' className={classes.cancelButton} onClick={() => handleClose()}>
						{t('generic.cancel')}
					</Button>
						{selectedData?.reportdata?.sensors && (<Button
							variant='contained'
							color='primary'
							style={{ marginLeft: '0.5rem' }}
							//onClick={() => handleSave(reportProps)}
							onClick={handleButtonClick}
						>
							{t('reports.saveReport')}
						</Button>)}
						<Dialog
							open={openDialog}
							onClose={handleCloseDialog}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'
						>
							<DialogTitle id='alert-dialog-title'>{t('reports.confirmSaveTitle')}</DialogTitle>
							<DialogContent>
								<DialogContentText id='alert-dialog-description'>
									{t('reports.confirmSaveMessage')}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleCloseDialog} color='primary'>
									{t('generic.cancel')}
								</Button>
								<Button onClick={handleConfirmSave} color='primary' autoFocus>
									{t('generic.confirm')}
								</Button>
							</DialogActions>
						</Dialog>
					<Button variant='contained' color='primary' style={{ marginLeft: '0.5rem' }} onClick={() => selectionDone()}>
						{t('reportPopups.subscribeButton')}
					</Button>
				</div>
			</div>
		</>
	);
}
